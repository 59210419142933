const baseUrl = process.env.NEXT_PUBLIC_BASE_URL_ASSETS as string;

export const appIcon192 = `${baseUrl}/app_icon_192.png`;
export const appIcon512 = `${baseUrl}/app_icon_512.png`;
export const bgLogin = `${baseUrl}/bglogin.png`;
export const emptyState = `${baseUrl}/empty-state.png`;
export const notFoundState = `${baseUrl}/not-found-state.png`;
export const favicon = `${baseUrl}/favicon.ico`;
export const greenVector = `${baseUrl}/green-vector.png`;
export const greyVector = `${baseUrl}/grey-vector.png`;
export const helpButton = `${baseUrl}/help-button.png`;
export const loading = `${baseUrl}/loading.gif`;
export const logo = `${baseUrl}/MyTEnS.png`;
export const SubstractUp = `${baseUrl}/SubtractUp.png`;
export const SubstractDown = `${baseUrl}/SubtractDown.png`;
export const InformasiBelumTersedia = `${baseUrl}/Informasi-belum-tersedia.png`;
export const BelumAdaReminder = `${baseUrl}/Belum-ada-reminder.png`;
export const NoActivity = `${baseUrl}/belum-ada-aktivitas.png`;
export const img_customer_service = `${baseUrl}/img_customer_service.png`;
export const helpdeskbtn = `${baseUrl}/helpdeskbtn.png`;
export const CustomerService = `${baseUrl}/Customer-Service.png`;
export const mobileHelpButton = `${baseUrl}/mobile-helpbtn.png`;
export const compPic = `${baseUrl}/compPic.png`;
export const submitted = `${baseUrl}/submitted.png`;
export const helpdeskMobileBtn = `${baseUrl}/helpdeskMobileBtn.png`;
export const BANNER = `${baseUrl}/img-main-banner.png`;
export const BANNER_2x = `${baseUrl}/img-main-banner@2x.png`;
export const BANNER_3x = `${baseUrl}/img-main-banner@3x.png`;
export const TRAFFIC = `${baseUrl}/img-traffic.png`;
export const TRAFFIC_2x = `${baseUrl}/img-traffic@2x.png`;
export const TRAFFIC_3x = `${baseUrl}/img-traffic@3x.png`;
export const INSTALL = `${baseUrl}/img-installation.png`;
export const INSTALL_2x = `${baseUrl}/img-installation@2x.png`;
export const INSTALL_3x = `${baseUrl}/img-installation@3x.png`;
export const EMAIL_CIRCLE = `${baseUrl}/email_circle.png`;
export const PHONE_CIRCLE = `${baseUrl}/phone_circle.png`;
export const EMPTY_STATE_LOCKED = `${baseUrl}/img-empty-state-locked-account.png`;
export const IMG_HELPER_ID = `${baseUrl}/img-banner_help-desk_id.png`;
export const IMG_HELPER_EN = `${baseUrl}/img-banner_help-desk_en.png`;
export const IMG_TELKOM = `${baseUrl}/telkom.png`;
export const CALL_SUPPORT = `${baseUrl}/img_helpdesk_center.jpg`;
export const HEADPHONE = `${baseUrl}/headphone.png`;
export const WRENCH = `${baseUrl}/wrench.png`;
export const COMMENT = `${baseUrl}/comment.png`;
export const IMG_SLIDER_01 = `${baseUrl}/img-slide-01.png`;
export const IMG_SLIDER_02 = `${baseUrl}/img-slide-02.jpg`;
export const DASHBOARD_IMAGE = `${baseUrl}/project-image.png`;
export const PERFORMANCE_IMAGE = `${baseUrl}/performance-image.png`;
export const SERVICE_IMAGE = `${baseUrl}/service-image.png`;
export const PROJECT_IMAGE = `${baseUrl}/project-image.png`;
export const IMG_BENEFIT_LP = `${baseUrl}/img-benefit-lp.png`;
export const IMG_SOLUSI_KOMPREHENSIF = `${baseUrl}/img-managed-it-security-service-optimisasi.png`;
export const IMG_PENGALAMAN = `${baseUrl}/img-omnichannel-communication-kepuasan-pelanggan.png`;
export const IMG_KEMUDAHAN_PEMANTAUAN = `${baseUrl}/img-iot-platform-deteksi-kondisi.png`;
export const IMG_EKOSISTEM_KUAT = `${baseUrl}/img-vpn-ip-luas&fleksibel.png`;
export const IMG_KEY_1 = `${baseUrl}/img_b_overview.png`;
export const IMG_KEY_2 = `${baseUrl}/img_b_info-proyek.png`;
export const IMG_KEY_3 = `${baseUrl}/img_b_performance.png`;
export const IMG_KEY_4 = `${baseUrl}/img_service_detail.png`;
export const IMG_SERVER_ERROR = `${baseUrl}/img-server-error.svg`;
export const IMG_PAGE_404 = `${baseUrl}/img-page-404@2x.png`;
export const IMG_DATA_404 = `${baseUrl}/img-data-404@2x.png`;
export const IMG_DATA_NOT_EXIST = `${baseUrl}/img-data-null.svg`;
export const IMG_LOST_CONNECTION = `${baseUrl}/img-lost-connection.svg`;
export const IMG_GAIN_INTEREST = `${baseUrl}/img_gain_contract.png`;
export const IMG_EMPTY_BOX = `${baseUrl}/img_empty_box.png`;
export const IMG_ABOUT_US = `${baseUrl}/img_banner_about.jpg`;
export const IMG_MANDIRI_LOGO = `${baseUrl}/bank-mandiri-logo.png`;
export const IMG_BNI_LOGO = `${baseUrl}/bank-bni-logo.png`;
export const IMG_BRI_LOGO = `${baseUrl}/bank-bri-logo.png`;
export const IMG_EXPLORE = `${baseUrl}/img_bg_button_explore_more.jpg`;
export const IMG_AKUN_TERKUNCI = `${baseUrl}/Akun-terkunci.png`;
export const IMG_WHATSAPP = `${baseUrl}/whatsapp.png`;
export const IMG_PLAY = `${baseUrl}/play.png`;
